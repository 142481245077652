import React from 'react'

const Section = ({ children }) => {
    return (
        <div className="block section">
            <header>
                { children }
            </header>
        </div>
    )
}

export default Section