import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { getAssetUrl } from '../../services/cdn'

const Hero = ({ src, size = 12, children, autoAlign = true, height = 768, x = 50, y = 50,  dim = 0, className = '', style, ...otherProps  }) => {
    return (
        <div className={`py-5 ${className}`}
            style={{ 
                backgroundSize: 'cover, cover',
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, ${dim}), rgba(0, 0, 0, ${dim}) ), url('${getAssetUrl(src)}')`,
                backgroundPosition: `${x}% ${y}%`,
                backgroundRepeat: 'no-repeat',
                height: `${height !== null ? height + 'px' : '100%'}`,
                ...style
            }}
            { ...otherProps }
        >
            {
                autoAlign ? 
                (
                    <Container className="mt-5 pt-5">
                        <Row>
                            <Col lg={size} className="text-lg-left align-self-center text-right">
                                { children }
                            </Col>
                        </Row>
                    </Container>
                )
                    :
                (children)
            }
        </div>
    )
}

export default Hero