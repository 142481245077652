import React from 'react'
import { Container as BlockContainer } from '../Blocks'
import { Container, Row, Col } from 'reactstrap'
import { getAssetUrl } from '../../services/cdn'


class CareerAndJobs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
            modalTitle: '',
            modalDescription: '',
            modalBottomDescription: '',
            modalSrc: '',
            modalLink: '',
            modalAlt: ''
        }
    }
    toggle = () => {
        this.setState((state, props) => {
            return {
                isModalOpen: !state.isModalOpen
            }
        })
    }
    open = (data) => {
        this.setState({
            isModalOpen: true,
            modalTitle: data.title,
            modalDescription: data.description,
            modalBottomDescription: data.bottomDescription,
            modalSrc: data.src,
            modalAlt: data.alt,
            modalLink: data.link
        })
    }
    render() {
        return (
            <>
                <BlockContainer padding={0} className="pb-5">
                    <Container>
                        <Row>
                            <Col md="12" >
                            {/* <h1 className="mb-3">Membership Form</h1>
                            <p align="center">
                              <img className="img-fluid d-block pt-5" alt="Membership Form" src={getAssetUrl('app/tmb_membershipform.png')} class="center" />
                            </p> */}
                            <p>
                                <div className='text-container'>
                                <h3 className="mb-0"><b>Pre-Employment Application</b></h3>
                                <p align="center" >
                                <img className="img-fluid d-block pt-5" alt="Career" src={getAssetUrl('app/SIDCCareers.png')}  />
                                </p>
                                </div>
                                <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/SIDC-PreEmploymentApplicationForm">
                                <button class="btn btn-primary mb-4" >Pre-Employment Application Form</button>
                                </a>
                            </p>
                            </Col>  
                        </Row>
                        
                    </Container>
                </BlockContainer>
                </>
        )
    }
}

export default CareerAndJobs
