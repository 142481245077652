import React from 'react'
import { getAssetUrl } from '../../services/cdn';

const Benefit = ({ title, children, iconClass, inverted = false, alignment = 'center', src = null }) => {
    return (
        <div className="block benefit"> 
            { 
                inverted ? 
                (
                    <>
                        <h4> <b>{ title }</b> </h4>
                        <p>{ children }</p>
                        { src === null ? 
                            <i className={`d-block ${iconClass} mb-2 text-primary`}></i> 
                            :
                            <img src={getAssetUrl(src)} alt={title} height="70px" />
                        }
                    </>
                )
                        :
                (
                    <>
                        { src === null ? 
                            <i className={`d-block ${iconClass} mb-2 text-primary`}></i> 
                            :
                            <img src={getAssetUrl(src)} alt={title} height="70px" />
                        }
                        <h4> <b>{ title }</b> </h4>
                        <div className={`text-${alignment}`}>
                            <p>{ children }</p>
                        </div>
                        
                    </>
                )
            }
        </div>
    )
}

export default Benefit