/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Footer from "./footer"
import Navbar from './navbar'
import Root from './root'

const Layout = ({ children }) => (
  <Root>
    <Navbar />
    <div>
      <main>{children}</main>
    </div>
    <Footer />
  </Root>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
