const brands = [
    {
        src: 'app/brands/SIDC-Feeds.png',
        alt:'SIDC Feeds',
        title: 'SIDC Feeds',
        description:
            '<p>Sorosoro Ibaba Development Coperative provides premium quality feeds for farm and backyard raiser in the Philippines.</p>' +
            '<p>SIDC has its own feedmill that produces an average of 9,000 bags per day. It uses state of the art machineries for the production of mash, pellet and crumble feeds for Swine, Poultry, Broilers and Ruminants.</p>' +
            '<p>A Certified ISO 9001:2015 Feedmill Plant with the large range of network in areas of CALABARZON, MIMAROPA, Central Luzon, Bicol and Visayas.</p>' +
            '<p>SIDC Feedmill Plants are strategically located at Batangas City, Capiz, Aklan and Palawan to serve the needs of its member and customer.</p>' +
            '<br />' +
            '<h4>Contact</h4>' +
            '<p>Sorosoro Karsada, Batangas City, Batangas</p>' +
            '<p>Phone No: (043) 723-3435 / 300-0015 / 300-0017 / 300-0021</p>' +
            '<p>Email: sidc1969_fm@yahoo.com.ph</p>'
    },
    {
        src: 'app/brands/KoopLikas.png',
        alt: 'KoopLikas',
        title: 'KoopLikas',
        description:
            '<p>SIDC offers quality Organic Fertilizer under the brand name "KoopLikas".</p>' +
            '<p>Kooplikas is an Organic Fertilizer from pork dung and chicken as a main ingredient.</p>' +
            '<p>It has been proven in many studies and research that the use of this type of fertilizer helps greatly to the further development of ground conditions for continuous use</p>',
        bottomDescription:
            '<p>It can restore the natural soil structure that is ideal for living microorganisms which will help to plant the necessary nutrients</p>' +
            '<br />' +
            '<p>It is Registered and Certified by Organic Certification Center of the Philippines and Bureau of Agriculture and Fishery Standard.</p>' +
            '<h4>Smart Tips for Kooplikas Organic Fertilizer Application</h4>' +
            '<table>' +
            '<thead>' +
            '<th>Crops / Vegetable</th>' +
            '<th>Quantity</th>' +
            '<th>Method</th>' +
            '</thead>' +
            '<tbody>' +
            '<tr>' +
            '<td>Plantation Crops (Corn, Rice, Sugar Cane and etc.)</td>' +
            '<td>10-20 sacks per hectare</td>' +
            '<td>Mix in the soil before planting </td>' +
            '</tr>' +
            '<tr>' +
            '<td>Solanaceous ( Egg Plant, Tomato, Pepper)</td>' +
            '<td>20-100 grams per plant</td>' +
            '<td>Application of fertilizer between the rows of growing crops (sidedress)</td>' +
            '</tr>' +
            '<tr>' +
            '<td>Legumes (Beans)</td>' +
            '<td>20 grams per plant</td>' +
            '<td>Application of fertilizer between the rows of growing crops (sidedress)</td>' +
            '</tr>' +
            '<tr>' +
            '<td>Leafy( Peachay, Cabbage, Lettuce and etc.)</td>' +
            '<td>50-100 grams per plant</td>' +
            '<td>Application of fertilizer between the rows of growing crops (sidedress)</td>' +
            '</tr>' +
            '<tr>' +
            '<td>Rootcrops (Raddish, Carrot, Potatoe)</td>' +
            '<td>30-40 grams per plant</td>' +
            '<td>Application of fertilizer between the rows of growing crops (sidedress)</td>' +
            '</tr>' +
            '<tr>' +
            '<td>Cucurbits (Squash, Ampalaya, Cucumber, and etc.)</td>' +
            '<td>30 - 40 grams per plant</td>' +
            '<td>Application of fertilizer between the rows of growing crops (sidedress)</td>' +
            '</tr>' +
            '</tbody>' +
            '</table>' +
            '<h4>Contact</h4>' +
            '<p>Kooplikas are available at SIDC Coop Stores</p>'
    },
    {
        src: 'app/brands/SIDCVet.png',
        alt:'SIDC Vet Products',
        title: 'SIDC Vet Products',
        description:
            '<p>SIDC Vet Products is another quality product of Sorosoro Ibaba Development Cooperative to serve our Hog and Poultry Industry.</p>' +
            '<p>Quality and affordable farm-tested Antibiotics and Vitamins for Hogs & Poultry. A reliable livestock raiser’s partner for a more profitable livestock production business.</p>' +
            '<p>These antibiotics and vitamins have been effective in improving the rate and efficiency of gain in swine, cattle, and poultry. Being sold in an affordable price, RightVet Water soluble vitamins and anti biotics are available at all Agri-Vet supply section of all Coop Supermarts, Coopmarts and SIDC Feeds outlet.</p>' +
            '<br />' +
            '<h4>SIDC Vet Products</h4>' +
            '<ul>' +
            '<li>RightVet Amoxicillin</li>' +
            '<li>RightVet Florfenicol</li>' +
            '<li>RightVet Norfloxxacin</li>' +
            '<li>CoopVet Chlortetracycline Hydrochloride + Vitamins A, B12 + Biotin</li>' +
            '<li>PremiereVet Doxycycline + Tiamulin Hydrogen Fumarate + Vitamin A, B12</li>' +
            '<li>BestVet Chloride +Vit. C + Sodium Bicarbonate + Sodium Chloride + Potassium Chloride</li>' +
            '</ul>' +
            '<h4>Contact</h4>' +
            '<p>SIDC Vet Products are available at SIDC Coop Stores</p>' 

    },
    {
        src: 'app/brands/Koopinoy.png',
        alt:'Koopinoy',
        title: 'Koopinoy',
        description:
            '<p>To instill the values of thrift and financial discipline among members, Sorosoro Ibaba Development Cooperative (SIDC) launched its Savings Mobilization Program last September 28, 1995. Its name was replace with SIDC Savings and Loans. It was in March 14, 2016 when this same entity was re-launched under the name of Koopinoy SIDC Savings and Loans bearing it’s now official logo.</p>' +
            '<p> It was merged to Sorosoro Credit Cooperative last September 2, 2002 and named as SIDC Savings and Loans. It accepts time deposit and regular savings deposits. In return, comparably high interest rates were offered to account holders, both regular and associate members. It also extends loans to members with a competitive interest rate. Other products being offered are Investment Program, </p>' +
            '<br />' +
            '<h4>Savings Product</h4>' +
            '<ul>' +
            '<li>Regular Savings</li>' +
            '<li>Time Deposit</li>' +
            '<li>Young Savers Club</li>' +
            '<li>My Future Savings Fund</li>' +
            '</ul>' +
            '<h4>Loan Product</h4>' +
            '<ul>' +
            '<li>Cash Loan</li>' +
            '<li>Emergency Loan</li>' +
            '<li>Institutional Loan Program</li>' +
            '<li>Expand Credit Line for Feeds (ECL)</li>' +
            '<li>Coop Pabahay</li>' +
            '<li>Car Loan</li>' +
            '<li>Motorcycle and Tricycle Loan</li>' +
            '<li>Memorial Loan</li>' +
            '<li>Special Loan</li>' +
            '<li>Agricultural Production Loan</li>' +
            '<li>AgriFarm Equipment Loan</li>' +
            '<li>Condominium Loan</li>' +
            '<li>Solar Power Financing Program Loan</li>' +
            '</ul>' +
            '<h4>Insurance</h4>' +
            '<h4>Investment</h4>' +
            '<h4>Contact</h4>' +
            '<p>Sorosoro Ibaba, Batangas City, Batangas</p>' +
            '<p>Phone No: (043) 300 6944</p>' +
            '<p>Email: sidcinvestment@yahoo.com</p>'
    },
    {
        src: 'app/brands/Agri-Trading-and-Financing.png',
        alt: 'Agri Trading and Financing',
        link: process.env.GATSBY_SITE_AGRICULTURE,
        title: 'Agri Trading and Financing',
        description:
            '<p>SIDC Agritrading and Financing  a one-stop shop where farming communities can access products and services of SIDC’s business lines for their agricultural activities. These ATFs are located in North Luzon ( Mayantoc, Guimba and Talavera.</p>' +
            '</ul>' +
            '</li>' +
            '</ol>' +
            '<h4>Contact</h4>' +
            '<p>ATF Mayantoc - 0919-075-5854<br> ATF Guimba - 0998-978-7396<br> ATF Talavera - 0939-919-3420</p>'

    },
    {
        src: 'app/brands/Coop-Supermart.png',
        alt:'Coop Supermart',
        link: process.env.GATSBY_SITE_CONSUMER,
        title: 'Coop Supermart',
        description:
            '<p>It offers a wide variety of reasonably priced commodities: groceries, electrical supplies and agricultural inputs at affordable prices. It is equipped with electronic cash registers utilizing a computer program.</p>' +
            '<p>Members can buy from an outlet, which is stone-throw from their place without being bothered by heavy traffic. </p>' +
            '<h4>Contact</h4>' +
            //'<p>Sorosoro Ibaba, Batangas City, Batangas</p>' +
            //'<p>Phone No: (+6343) 300 - 4058 local 112</p>' +
            //'<p>Email: paiwi@sidc.coop</p>'
            '<img src="https://cdn.sidc.coop/assets/app/brands/CSM-Contacts.png"/>'
    },
    {
        src: 'app/brands/Coopmart.png',
        alt:'Coopmart',
        link: process.env.GATSBY_SITE_CONSUMER,
        title: 'Coopmart',
        description:
            '<p>It offers a wide variety of reasonably priced commodities: groceries, electrical supplies and agricultural inputs at affordable prices. It is equipped with electronic cash registers utilizing a computer program.</p>' +
            '<p>Members can buy from an outlet, which is stone-throw from their place without being bothered by heavy traffic. </p>' +
            '<h4>Contact</h4>' +
            //'<p>Sorosoro Ibaba, Batangas City, Batangas</p>' +
            //'<p>Phone No: (+6343) 300 - 4058 local 112</p>' +
            //'<p>Email: paiwi@sidc.coop</p>' +

            '<img src="https://cdn.sidc.coop/assets/app/brands/CM-Contacts.png"/>' 

    },
    {
        src: 'app/brands/1-Click-Mart.png',
        alt:'1 Click Mart',
        link: process.env.GATSBY_SITE_CONSUMER,
        title: '1 Click Mart',
        description:
            '<p>It offers a wide variety of reasonably priced commodities: groceries, electrical supplies and agricultural inputs at affordable prices. It is equipped with electronic cash registers utilizing a computer program.</p>' +
            '<p>Members can buy from an outlet, which is stone-throw from their place without being bothered by heavy traffic. </p>' +
            '<h4>Contact</h4>' +
            '<p>1 Click Mart Sorosoro - Sorosoro Ilaya, Batangas City, Batangas - 0919 -069-2787,' +
            '1 Click Mart Balagtas - Balagtas, Batangas City, Batangas - 0920-971-0479</p>'
            
    },
    {
        src: 'app/brands/KoopHardware.png',
        alt: 'KoopHardware',
        link: process.env.GATSBY_SITE_CONSUMER,
        title: 'KoopHardware',
        description:
            '<p>The First DIY store of SIDC established at December 2017 in Tinga Itaas, Batangas. It provides qualiy and reasonable price materials for construction and home improvement.</p>' +
            '<h4>Contact</h4>' +
            '<p>Tinga Labac, Batangas City, Batangas<br>Phone No. 0920-583-1926 / 0917-815-7108</p>' +
            '<p>Gulod Labac, Batangas City, Batangas<br>Phone No. 0998-964-0454</p>' +
            '<p>Dela Paz Aplaya, Batangas City, Batangas<br>Phone No. 0949-992-5873</p>' +
            '<p>Libjo, Batangas City, Batangas<br>Phone No. 0998-961-7775</p>' +
            '<p>Taysan, San Jose, Batangas<br>Phone No. 0947-897-0565</p>' +
            '<p>San Agustin, Ibaan, Batangas<br>Phone No. 0998-5301405</p>'
    },
    {
        src: 'app/brands/SIDC-Petron.png',
        alt: 'SIDC Petron',
        title: 'SIDC Petron',
        description:
            '<p>Provides gasoline services, auto supply and other services like, washing, greasing , Vulcanizing, Change Oil and battery Charging to members. </p>' +
            '<h4>Contact</h4>' +
            '<p>Balagtas, Batangas City, Batangas</p>' +
            '<p>Phone No: 043) 783-0368/(043)300-3038</p>'
    },
    {
        src: 'app/brands/SIDC-Gas.png',
        alt: 'SIDC Gas',
        title: 'SIDC Gas',
        description:
            '<p>Provides gasoline services, auto supply and other services like, washing, greasing , Vulcanizing, Change Oil and battery Charging to members. </p>' +
            '<h4>Contact</h4>' + 
            '<p>Banay-Banay, Lipa City, Batangas <br>Phone No: 0975-188-0897</p>' +
            '<p>Sta Rita, Pinamalayan, Oriental Mindoro<br>Phone No: (043) 284-4382 / 0919-068-0599</p>'
            
    },
    {
        src: 'app/brands/SIDC-SeaOil.png',
        alt: 'SIDC Sea Oil',
        title: 'SIDC Sea Oil',
        description:
            '<p>Provides gasoline services, auto supply and other services like, washing, greasing , Vulcanizing, Change Oil and battery Charging to members. </p>' +
            '<h4>Contact</h4>' +
            '<p>Manghinao Proper, Bauan, Batangas<br>Phone: 0998-582-0359</p>'
            
    },
    // {
    //     src: 'app/brands/Sorosoro-Springs.png',
    //     alt:'Sorosoro Springs',
    //     title: 'Sorosoro Springs',
    //     description:
    //         '<p>A 7 hectare agro-eco tourism project where you can explore the beauty of nature, with amenities for company team-building activities, summer outings and retreats abound.</p>' +
    //         '<p>Sorosoro Springs and Advenure Park is Department of Tourism certified</p>' +
    //         '<p>Located at Brgy  Sorosoro Ibaba, Batangas City. It also offers hanging bridges, and the first \'Airbike\' ride in Batangas.</p>' +
    //         '<br />' +
    //         '<h4>We offer the following</h4>' +
    //         '<ul>' +
    //         '<li>Teambuiding of Institutional Accounts</li>' +
    //         '<li>Seminars & Trainings</li>' +
    //         '<li>Retreats and Recollection</li>' +
    //         '<li>Venue for Garden Wedding</li>' +
    //         '<li>Venue for Birthday Celebration</li>' +
    //         '<li>Recommended Company Outings</li>' +
    //         '<li>Venue for Camping</li>' +
    //         '</ul>' +
    //         '<h4>Contact</h4>' +
    //         '<p>Sorosoro Karsada, Batangas City, Batangas</p>' +
    //         '<p>Phone No: (+6343) 706-1435 / 0917-867-6801</p>' +
    //         '<p>Email: sorosorosprings@sidc.coop</p>'
    // },
    {
        src: 'app/brands/Digital-Fiber-TV.png',
        alt: 'Digital Fiber TV & Internet',
        title: 'Digital Fiber TV & Internet',
        description:
            '<p>SIDC Digital Fiber TV & Internet Business started in 1999. It offers 80 SD, 20 HD channels plus 5 to 10 Mbps Internet Connection Speed at Super Affordable and Convenient Plans.</p>' +
            '<h4>Contact</h4>' +
            '<p>Sorosoro Ibaba, Batangas City</p>' +
            '<p>Phone No: 0939-908-6312, 0919-067-2458 & (043)300 - 4058 Loc.110</p>'
    },
    {
        src: 'app/brands/SIDC-AquaCare.png',
        alt: 'SIDC Aquacare',
        title: 'SIDC Aquacare',
        description:
            '<p>SIDC Water Refilling Station started in 2006.  SIDC Aqua Care is the brand nameof SIDC Purified water that undergoes 18-stage purification process.  Members can be sure of safe drinking water for their family.</p>' +
            '<h4>Contact</h4>' +
            '<p>Balagtas, Batangas City, Batangas</p>' +
            '<p>Phone No: (043) 783-0368/(043)300-3038</p>'
    },
    {
        src: 'app/brands/KooPrints.png',
        alt: 'KooPrints',
        title: 'KooPrints',
        description:
            '<p>This facility of SIDC offers offset and tarpaulin printing services to all customers with printing services need</p>' +
            '<h4>Contact</h4>' +
            '<p>Sorosoro Ibaba, Batangas City</p>' +
            '<p>Phone No: (043) 300-4058 to 59 local 157 and 219 or 0919-063-0569</p>'
    },
    {
        src: 'app/brands/Golden-Lays-Eggs.jpg',
        alt: 'Golden Lays Egg',
        title: 'Golden Lays Egg',
        description:
            "<p>Golden Lays egg is another consumer product brand of Sorosoro Ibaba Development Cooperative. These eggs were produced from the cooperative's very own Layer Farm. SIDC Layer Farm is located at Balanga, Ibaan Batangas.</p>" +
            '<h4>Contact</h4>' +
            '<p>Sorosoro Ibaba, Batangas City, Batangas</p>' +
            '<p>Phone No: 0905-758-0417</p>'
    }
]

export default brands
