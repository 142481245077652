import React from 'react'
import { Container as BlockContainer } from '../Blocks'
import { getAssetUrl } from '../../services/cdn'
import { UncontrolledCarousel, Row, Col, Container } from 'reactstrap'

const CoreValues = () => (
    <BlockContainer>
      <Container>
        <Row>
          <Col sm="12">
            <UncontrolledCarousel 
              items={[
                {
                  src: getAssetUrl('app/SIDC-Vision.jpg'),
                  altText: 'SIDC VISION',
                  caption: ''
                },
                {
                  src: getAssetUrl('app/SIDC-Mission.jpg'),
                  altText: 'SIDC Mission',
                  caption: ''
                },
                {
                  src: getAssetUrl('app/Core-Values.jpg'),
                  altText: 'Core Values',
                  caption: ''
                }
              ]}
              controls />
          </Col>
        </Row>
      </Container>
    </BlockContainer>
)

export default CoreValues