import React from 'react'
import { Container as BlockContainer } from '../Blocks'
import { Container, Row, Col } from 'reactstrap'



class ElectionSurvey extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
            modalTitle: '',
            modalDescription: '',
            modalBottomDescription: '',
            modalSrc: '',
            modalLink: '',
            modalAlt: ''
        }
    }
    toggle = () => {
        this.setState((state, props) => {
            return {
                isModalOpen: !state.isModalOpen
            }
        })
    }
    open = (data) => {
        this.setState({
            isModalOpen: true,
            modalTitle: data.title,
            modalDescription: data.description,
            modalBottomDescription: data.bottomDescription,
            modalSrc: data.src,
            modalAlt: data.alt,
            modalLink: data.link
        })
    }
    render() {
        return (
            <>
                <BlockContainer padding={0} className="pb-5">
                    <Container>
                        <Row>
                            <Col md="12" >
                            <h1 className="mb-3">Election Survey</h1>
                            <h4 className="mb-3">Please click the button</h4>
                            <p>
                                <a target="_blank"  rel="noopener noreferrer" href="https://forms.gle/UBkoFk8sFhtJ149R8">
                                <button className="btn btn-primary" >Survey Link</button>
                                </a>
                            </p>
                            </Col>
                        </Row>
                    </Container>
                </BlockContainer>
                </>
        )
    }
}

export default ElectionSurvey
