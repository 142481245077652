import React from 'react'

const Header = ({ title, children, underlined = true }) => {
    return (
        <div className="block title-header">
            <h1>{ title }</h1>
            { underlined && <span className="underscore"></span> }
            <p>{ children }</p>
        </div>
    )
}

export default Header