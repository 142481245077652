import React from 'react'
import PropTypes from 'prop-types'
import './vendors.js'
import DataPrivacyModal from './Sections/DataPrivacyModal'
//import MessengerCustomerChat from 'react-messenger-customer-chat';

class Root extends React.Component {
    constructor(props) {
        super(props);
        this.state = {show: false};
    }

    showModal() {
        this.setState({show: true});
    }

    hideModal() {
        this.setState({show: false});
    }




	//<MessengerCustomerChat
	//			pageId="835447736585751"
	//			appId="454563365400103"
	//			htmlRef="https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js"/>,
	//			document.getElementById('demo')
    render() {
        return (
            <div id="root">
                { this.props.children }
                {/* <MessengerCustomerChat style={{position:"fixed", bottom:"50px", right:"10px"}} pageId="835447736585751" appId="454563365400103"
                 htmlRef="https://mccdn.me/assets/js/widget.js"/> */}
                 <DataPrivacyModal/>
            </div>
                )
            }
}

Root.propTypes = {
    children: PropTypes.node.isRequired,
  }

export default Root
