import React from 'react'
import { Container as BlockContainer } from '../Blocks'
import { Container, Row, Col } from 'reactstrap'
import post from '../../data/posts'
import { getAssetUrl } from '../../services/cdn'
import { FacebookProvider, Page  } from 'react-facebook';


class FacebookPost extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
            modalTitle: '',
            modalDescription: '',
            modalBottomDescription: '',
            modalSrc: '',
            modalLink: '',
            modalAlt: ''
        }
    }
    toggle = () => {
        this.setState((state, props) => {
            return {
                isModalOpen: !state.isModalOpen
            }
        })
    }
    open = (data) => {
        this.setState({
            isModalOpen: true,
            modalTitle: data.title,
            modalDescription: data.description,
            modalBottomDescription: data.bottomDescription,
            modalSrc: data.src,
            modalAlt: data.alt,
            modalLink: data.link
        })
    }
    render() {
        return (
            <>
                <BlockContainer>
                    <Container>
                        <Row>
                            <Col md="12">
                                <h1 className="mb-3">News and Updates</h1>
                                <p align="center" >
                                <img className="img-fluid d-block pt-5" alt="SIDC Award" src={getAssetUrl('app/ASEAN.jpg')}  />
                                <img className="img-fluid d-block pt-5" alt="SIDC Wins" src={getAssetUrl('app/SIDCWins.png')}  />
                                <img className="img-fluid d-block pt-5" alt="SIDC Wins" src={getAssetUrl('app/Awards.jpg')}  />
                                </p>
                                <h5>𝗦𝗼𝗿𝗼𝘀𝗼𝗿𝗼 𝗜𝗯𝗮𝗯𝗮 𝗗𝗲𝘃𝗲𝗹𝗼𝗽𝗺𝗲𝗻𝘁 𝗖𝗼𝗼𝗽𝗲𝗿𝗮𝘁𝗶𝘃𝗲 𝗥𝗲𝗰𝗲𝗶𝘃𝗲𝘀 𝗔𝘄𝗮𝗿𝗱𝘀 𝗮𝘁 𝗟𝗶𝘃𝗲𝘀𝘁𝗼𝗰𝗸 𝗣𝗵𝗶𝗹𝗶𝗽𝗽𝗶𝗻𝗲𝘀 𝟮𝟬𝟮𝟰</h5>
                                <p>The Sorosoro Ibaba Development Cooperative (SIDC) has once again proven its leadership in the agricultural and cooperative sectors by receiving two distinguished awards at the Livestock Philippines 2024 Recognition. The event, held on May 22, 2024, at the World Trade Center in Pasay City, was graced by the presence of key figures from the Department of Agriculture and Informa Markets.</p>
                                <p>SIDC was awarded the “Outstanding Swine Farm” and “Outstanding Layer Farm” titles, further solidifying its reputation for excellence in sustainable farming and community development. These awards were presented by Honorable Undersecretary Deogracias Victor Savellano of the Department of Agriculture and Ms. Rungphech Chitanuwat, the Country General Manager of Informa Markets in the Philippines.</p>
                                <p>Dr. Angelito B. Bagui, Chairperson of the Board, and Mr. Rico B. Geron, CEO and Former Congressman, proudly accepted the awards on behalf of SIDC. Their visionary leadership and commitment to innovation have been instrumental in the cooperative’s success and these accolades serve as a recognition of their tireless efforts.</p>
                                <p>The Livestock Philippines 2024 Recognition is part of a larger expo running from May 22-24, which showcases the latest trends and opportunities in the livestock industry. SIDC’s achievements at this prestigious event underscore its role as a frontrunner in the field and its potential to shape the future of agriculture in the Philippines.</p>
                                <p>For more insights into the Livestock Philippines 2024 expo and to learn about other innovations and offerings, interested parties are encouraged to visit SIDC at Booth K-11.</p>
                                <p align="left">#PhillipinesLivestockExpo2024 <br/>
                                #OutstandingLayerAndSwineFarms<br/>
                                #SIDC55yearsoftouchinglives <br/>
                                </p>
                                <p><b>𝐷𝑖𝑠𝑐𝑙𝑎𝑖𝑚𝑒𝑟: 𝑃𝑢𝑟𝑠𝑢𝑎𝑛𝑡 𝑡𝑜 𝐷𝑎𝑡𝑎 𝑃𝑟𝑖𝑣𝑎𝑐𝑦 𝐴𝑐𝑡 𝑜𝑓 2012, 𝑡ℎ𝑒 𝑟𝑖𝑔ℎ𝑡𝑓𝑢𝑙 𝑜𝑤𝑛𝑒𝑟 𝑜𝑓 𝑡ℎ𝑒 𝑝ℎ𝑜𝑡𝑜(𝑠) 𝑐𝑜𝑛𝑠𝑒𝑛𝑡𝑒𝑑 𝑡𝑜 𝑡ℎ𝑒 𝑝𝑢𝑏𝑙𝑖𝑐 𝑝𝑜𝑠𝑡𝑖𝑛𝑔 𝑜𝑓 𝑡ℎ𝑒 𝑝𝑖𝑐𝑡𝑢𝑟𝑒(𝑠). 𝐿𝑖𝑘𝑒𝑤𝑖𝑠𝑒, 𝑡ℎ𝑖𝑠 𝑝𝑜𝑠𝑡 𝑖𝑠 𝑖𝑛𝑡𝑒𝑛𝑑𝑒𝑑 𝑜𝑛𝑙𝑦 𝑓𝑜𝑟 𝑆𝐼𝐷𝐶 𝑎𝑑𝑣𝑒𝑟𝑡𝑖𝑠𝑒𝑚𝑒𝑛𝑡 𝑎𝑛𝑑 𝑚𝑎𝑟𝑘𝑒𝑡𝑖𝑛𝑔 𝑝𝑢𝑟𝑝𝑜𝑠𝑒 𝑎𝑛𝑑 𝑛𝑜𝑡 𝑓𝑜𝑟 𝑎𝑛𝑦 𝑜𝑡ℎ𝑒𝑟 𝑝𝑢𝑟𝑝𝑜𝑠𝑒</b></p>
                            </Col>
                        </Row>
                    </Container>
                </BlockContainer>
                <BlockContainer padding={0} className="pb-5">
                    <Container>
                        <Row>
							                 <Col md="12">

								                       <FacebookProvider appId="1157676924295172">
									                              {
										                              post.map((brand, key) => (
											                              <React.Fragment key={key}>
                                                     <Page href={`${brand.description}`} tabs="timeline"/><br/>
											                              </React.Fragment>
                                                    ))
			                                           }
                                                      </FacebookProvider>
                                </Col>
						           </Row>
                    </Container>
                </BlockContainer>
            </>
        )
    }
}

export default FacebookPost
