import React from 'react'
import { navigate } from 'gatsby'
import { getAssetUrl } from '../../services/cdn'
import Header from './Header'
import { Button, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

export const TileModalContainer = ({ isOpen, onToggle, src, link = null, alt = "MODAL", title, description, bottomDescription = '', ...otherProps }) => {
    const internal = /^\/(?!\/)/.test(link)
    return (
        <Modal isOpen={isOpen} fade {...otherProps}>
            <ModalHeader toggle={onToggle}></ModalHeader>
            <ModalBody>
                <section>
                    <div className="block section">
                        <header>
                            <Header title={title}></Header>
                        </header>
                        <section>
                            <div className="content">
                                <Row>
                                    <Col sm="12" lg="6">
                                        <div className="image">
                                            <img src={getAssetUrl(src)} alt={alt} />
                                        </div>
                                    </Col>
                                    <Col sm="12" lg="6">
                                        <div className="description text-left" dangerouslySetInnerHTML={{ __html: description }} />
                                    </Col>
                                    <Col sm="12">
                                        <div className="description text-left" dangerouslySetInnerHTML={{ __html: bottomDescription }} />
                                    </Col>
                                </Row>
                            </div>
                        </section>
                    </div>
                </section>
            </ModalBody>
            <ModalFooter>
                <div className="w-100 d-inline-flex justify-content-center justify-content-sm-end">
                    { 
                        link !== null &&
                        (
                            internal ? 
                                <Button color="primary" onClick={() => {
                                    onToggle();
                                    navigate(link)
                                }}>
                                    Link to Page
                                </Button>
                                    :                                
                                <a href={link} className="btn btn-primary">Link to Page</a>
                        )
                    }
                    <Button color="secondary" onClick={onToggle}>Close</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export const TileModal = ({ className = '', onClick, src, alt = "TILE", data }) => (
    <div className="tile border">
        <div className={`brands border ${className}`} onClick={onClick}>
            <img src={getAssetUrl(src)} alt={alt} />
        </div>
    </div>
)