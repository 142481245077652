import React from 'react'
import { Container as BlockContainer } from '../Blocks'
import { Container, Row, Col } from 'reactstrap'
import { getAssetUrl } from '../../services/cdn'


class ElectionAnnouncement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
            modalTitle: '',
            modalDescription: '',
            modalBottomDescription: '',
            modalSrc: '',
            modalLink: '',
            modalAlt: ''
        }
    }
    toggle = () => {
        this.setState((state, props) => {
            return {
                isModalOpen: !state.isModalOpen
            }
        })
    }
    open = (data) => {
        this.setState({
            isModalOpen: true,
            modalTitle: data.title,
            modalDescription: data.description,
            modalBottomDescription: data.bottomDescription,
            modalSrc: data.src,
            modalAlt: data.alt,
            modalLink: data.link
        })
    }
    render() {
        return (
            <>
                <BlockContainer padding={0} className="pb-5">
                    <Container>
                        <Row>
                            <Col md="12" >
                            <h1 className="mb-3">2024 Election Annoucement</h1>
                            <p align="center" >
                                <img className="img-fluid d-block pt-5" alt="Election Annoucement" src={getAssetUrl('app/ElectionAnnouncement.jpg')}  />
                            </p>
                            {/* <p>
                                <a target="_blank"  rel="noopener noreferrer" href={getAssetUrl('../documents/CertificateofCandidacy.pdf')}>
                                <button className="btn btn-primary" >Download Certificate</button>
                                </a>
                            </p> */}
                            </Col>
                        </Row>
                    </Container>
                </BlockContainer>
                </>
        )
    }
}

export default ElectionAnnouncement
