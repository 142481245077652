import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { getAssetUrl } from '../../services/cdn'

const HeroVideo = ({ src, posterSrc, size = 12, children, height = 768, heightUnit = 'px', x = 50, y = 50, controls = false, loop = false, autoplay = true, muted = true, ...otherProps  }) => {
    let pathSplit = src.split(".")
    let format = pathSplit.pop()
    let filePath = pathSplit.join(".")
    return (
        <div className="hero_video pt-5" style={{display: "flex",justifyContent: "center",alignItems: "center", position: "relative", height: `${height !== null ? height + heightUnit : '100vh'}`, overflow: 'hidden'}} {...otherProps}>
            <video 
                poster={posterSrc}
                style={{
                    position: "absolute",
                    height: `${height !== null ? height + heightUnit : 'auto'}`,
                    zIndex: '0',
                    width: '100%',
                    top: `64px`,
                }}
                loop={loop}
                autoPlay={autoplay}
                muted={muted}
                controls={controls}
            >
                <source src={`${getAssetUrl(filePath)}.${format}`} type={`video/${format}`} />
                <p>Your browser doesn't support HTML5 video. Here is a <a href={getAssetUrl('app/Video/sample-hero.webm')}>link to the video</a> instead.</p>
            </video>
            <Container className="pb-5">
                <Row>
                    <Col lg={size} className="text-lg-left align-self-center text-right py-5">
                        { children }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default HeroVideo