import React from 'react'
import { Container as BlockContainer, TileModal, TileModalContainer } from '../Blocks'
import { Container, Row, Col } from 'reactstrap'
import brands from '../../data/brands'

class BrandsAndServices extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
            modalTitle: '',
            modalDescription: '',
            modalBottomDescription: '',
            modalSrc: '',
            modalLink: '',
            modalAlt: ''
        }
    }
    toggle = () => {
        this.setState((state, props) => {
            return {
                isModalOpen: !state.isModalOpen
            }
        })
    }
    open = (data) => {
        this.setState({ 
            isModalOpen: true,
            modalTitle: data.title,
            modalDescription: data.description,
            modalBottomDescription: data.bottomDescription,
            modalSrc: data.src,
            modalAlt: data.alt,
            modalLink: data.link
        })
    }
    render() {
        return (
            <>
                <BlockContainer>
                    <Container>
                        <Row>
                            <Col md="12">
                                <h1 className="mb-3">Products and Services</h1>
                            </Col>
                        </Row>
                    </Container>
                </BlockContainer>
                <BlockContainer padding={0} className="pb-5">
                    <Container>
                        <Row>
                            {
                                brands.map((brand, key) => (
                                    <Col key={`${key}-${brand.title}`} sm="12" md="4" lg="3">
                                        <TileModal 
                                            onClick={() => { 
                                                this.open({
                                                    title: brand.title,
                                                    description: brand.description,
                                                    bottomDescription: brand.bottomDescription,
                                                    src: brand.src,
                                                    alt: brand.alt,
                                                    link: brand.link
                                                })
                                            }}
                                            src={brand.src}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                    </Container>
                </BlockContainer>
                <TileModalContainer
                    isOpen={this.state.isModalOpen}
                    onToggle={this.toggle}
                    title={this.state.modalTitle}
                    description={this.state.modalDescription}
                    bottomDescription={this.state.modalBottomDescription}
                    src={this.state.modalSrc}
                    link={this.state.modalLink}
                    alt={this.state.modalAlt}
                    size="xl"
                />
            </>
        )
    }
}

export default BrandsAndServices