import React from 'react'
import { Container as BlockContainer } from '../Blocks'
import { Container, Row, Col } from 'reactstrap'
import { getAssetUrl } from '../../services/cdn'


class MembersForOwnership extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
            modalTitle: '',
            modalDescription: '',
            modalBottomDescription: '',
            modalSrc: '',
            modalLink: '',
            modalAlt: ''
        }
    }
    toggle = () => {
        this.setState((state, props) => {
            return {
                isModalOpen: !state.isModalOpen
            }
        })
    }
    open = (data) => {
        this.setState({
            isModalOpen: true,
            modalTitle: data.title,
            modalDescription: data.description,
            modalBottomDescription: data.bottomDescription,
            modalSrc: data.src,
            modalAlt: data.alt,
            modalLink: data.link
        })
    }
    render() {
        return (
            <>
                <BlockContainer padding={0} className="pb-5">
                    <Container>
                        <Row>
                            <Col md="12" >
                            <h1 className="mb-3">List of Members For Ownership</h1>
                            <p align="center" >
                              <img className="img-fluid d-block pt-5" alt="List of Members For Ownership" src={getAssetUrl('app/tmbOwnershipList.png')} class="center" />
                            </p>
                            <p>
                                <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/1UaBfSRRJzq0rynbrCv1CTFwP4QGKMeb6/edit?usp=sharing&ouid=100087059060150333508&rtpof=true&sd=true">
                                <button class="btn btn-primary" >Download File</button>
                                </a>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                <a target="_blank" rel="noopener noreferrer" href="https://forms.gle/wHKzc2oDjawPvihG9">
                                <button class="btn btn-primary" >Click here to Register</button>
                                </a>
                            </p>

                            </Col>
                        </Row>
                    </Container>
                </BlockContainer>
                </>
        )
    }
}

export default MembersForOwnership
