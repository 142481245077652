const posts = [
    { 
        src: 'https://www.facebook.com/SIDCOfficial/posts/1712160795581103', 
        alt:'Link', 
        title: 'Post1', 
        description: 
            'https://www.facebook.com/SIDCOfficial/posts/1712160795581103' 
    },
]

export default posts