import React from 'react'
import { Container as BlockContainer } from '../Blocks'
import { Container, Row, Col } from 'reactstrap'
import { getAssetUrl } from '../../services/cdn'


class MembershipForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
            modalTitle: '',
            modalDescription: '',
            modalBottomDescription: '',
            modalSrc: '',
            modalLink: '',
            modalAlt: ''
        }
    }
    toggle = () => {
        this.setState((state, props) => {
            return {
                isModalOpen: !state.isModalOpen
            }
        })
    }
    open = (data) => {
        this.setState({
            isModalOpen: true,
            modalTitle: data.title,
            modalDescription: data.description,
            modalBottomDescription: data.bottomDescription,
            modalSrc: data.src,
            modalAlt: data.alt,
            modalLink: data.link
        })
    }
    render() {
        return (
            <>
                <BlockContainer padding={0} className="pb-5">
                    <Container>
                        <Row>
                            <Col md="12" >
                            {/* <h1 className="mb-3">Membership Form</h1>
                            <p align="center">
                              <img className="img-fluid d-block pt-5" alt="Membership Form" src={getAssetUrl('app/tmb_membershipform.png')} class="center" />
                            </p> */}
                            <p>
                                <div className='text-container'>
                                <h3 className="mb-0"><b>Be a SIDC Member Now! </b></h3>
                                <h4 className="mb-0"><b>Click here</b></h4>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#169f0d" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"><path d="M12 5v13M5 12l7 7 7-7"/></svg>
                                </div>
                                <a target="_blank" rel="noopener noreferrer" href="https://emembership.sidc.coop/auth/login">
                                <button class="btn btn-primary mb-4" >Online Membership Application Form</button>
                                </a>
                            </p>
                            </Col>  
                            <Col md="12" >
                            <h1 className="mb-3">List of Membership ID for Release</h1>
                            <p align="center">
                              <img className="img-fluid d-block pt-5" alt="List of Membership ID for Release" src={getAssetUrl('app/tmb_IDRelease.png')} class="center" />
                            </p>
                            <p>
                                <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/11omtO0otO79Uj0pO6fGSgKDGHwNCUic-MZiciYFT7U4/edit#gid=0">
                                <button class="btn btn-primary" >Download List</button>
                                </a>
                            </p>
                            </Col> 
                        </Row>
                        
                    </Container>
                </BlockContainer>
                </>
        )
    }
}

export default MembershipForm
