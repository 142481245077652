import React from 'react'
import { Form, FormGroup, Col, Row, Input, Button } from 'reactstrap'

class Contact extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: '',
            lastName: '',
            company: '',
            emailAddress: '',
            phoneNumber: '',
            country: '',
            subject: '',
            message: ''
        }
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    reset = () => {
        this.setState({
            firstName: '',
            lastName: '',
            company: '',
            emailAddress: '',
            phoneNumber: '',
            country: '',
            subject: '',
            message: ''
        })
    }
    send = (e) => {
        e.preventDefault()

        let formData = new FormData()
        for (let key in this.state) {
            formData.append(key, this.state[key])
        }

        fetch('https://api.sidc.coop/website/main/sendContact.php', {
            method: 'POST', body: formData
        })
        .then(response => response)
        .then(data => {
            alert('Your contact request has been sent. Please wait for us to connect with you. Thank you')
			console.log(data)
            return data
        })
        .catch(error => {
            console.log(error)
            alert('Failed to send your contact request to our system. Please send us an email or call through our landline. Thank you.')
        })

        this.reset()
    }
    render() {
        return(
            <Form onSubmit={this.send}>
                <Row form>
                    <Col md="6">
                        <FormGroup>
                            <Input value={this.state.firstName} onChange={this.handleChange} type="text" name="firstName" id="contactFirstName" placeholder="First Name" required />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                                <Input value={this.state.lastName} onChange={this.handleChange} type="text" name="lastName" id="contactLastName" placeholder="Last Name" required />
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup> 
                    <Input value={this.state.company} onChange={this.handleChange} type="text" name="company" id="contactCompany" placeholder="Company" required /> 
                </FormGroup>
                <FormGroup> 
                    <Input value={this.state.emailAddress} onChange={this.handleChange} type="email" name="emailAddress" id="contactEmailAddress" placeholder="Email" required /> 
                </FormGroup>
                <Row form>
                    <Col md="6">
                        <FormGroup>
                            <Input value={this.state.phoneNumber} onChange={this.handleChange} type="text" name="phoneNumber" id="contactPhoneNumber" placeholder="Phone (optional)" />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Input value={this.state.country} onChange={this.handleChange} type="text" name="country" id="contactCountry" placeholder="Country" required />
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <Input value={this.state.subject} onChange={this.handleChange} type="text" name="subject" id="contactSubject" placeholder="Subject" required />
                </FormGroup>
                <FormGroup>
                    <Input value={this.state.message} onChange={this.handleChange} type="textarea" name="message" id="contactMessage" placeholder="Your message" row="5" required />
                </FormGroup>
                <Button color="primary">Submit</Button>
            </Form>
        )
    }
}

export default Contact