import React from 'react'
import { Link } from "gatsby"
import { Location, navigate } from '@reach/router'
import { Container, DropdownItem, DropdownMenu, UncontrolledDropdown, DropdownToggle, Collapse, Navbar as BootstrapNavbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { getAssetUrl } from '../services/cdn';

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          collapsed: true,
          noNavbar: true,
          mobile: false,
        }

        this.mobileBreakpoint = 540
        this.noNavbarPaths = [
            /(\/anniversary[/]*)/,
            // /(\/career[/]*[\w-/]*)/,
            // /(\/chairperson-message[/]*)/
        ]
      }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            this.setState({
                noNavbar: this.shouldHaveNoNavbar(window.location.pathname)
            })
            window.addEventListener('scroll', this.handleScrollChange)
            window.addEventListener('resize', this.handleSizeChange)
        }
    }

    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('scroll', this.handleScrollChange)
            window.removeEventListener('resize', this.handleSizeChange)
        }
    }

    handleScrollChange = () => {
        if (window.scrollY > 15) {
            if (!this.hasNavbar()) {
                if (this.shouldHaveNoNavbar(window.location.pathname)) {
                    console.log('add')
                    this.add()
                }
            }
        }
        else {
            if (this.hasNavbar()) {
                if (this.shouldHaveNoNavbar(window.location.pathname)) {
                    console.log('remove')
                    this.remove()
                }
            }
        }
    }

    handleSizeChange = () => {
        const width = window.innerWidth
        const inMobile = width <= this.mobileBreakpoint

        // would only re-render if the current state mobile is not matched with the current width of the window
        if (this.state.mobile !== inMobile) {
            this.setState({ mobile: inMobile })
        }
    }

    hasNavbar = () => {
        if (typeof window !== 'undefined') {
            return !window.jQuery('#navbar').hasClass('navbar-ontop')
        }
        else {
            return true
        }
    }

    add = () => {
        this.setState({
            noNavbar: false
        })
    }

    remove = () => {
        this.setState({
            noNavbar: true
        })
    }

    toggleNavbar = () => {
        this.setState((state, props) => {
            return { collapsed: !state.collapsed }
        });
    }

    goHome = (e) => {
        e.preventDefault()
        navigate('/')
    }

    shouldHaveNoNavbar = (currentPath) => {
        let result = false
        this.noNavbarPaths.forEach(path => {
            if (path.test(currentPath)) {
                result = true;
            }
        })
        return result
    }

    //
    render() {
        return (
            <Location>
                {
                    ({ location, navigate }) => (
                        <BootstrapNavbar id="navbar" fixed="top" dark expand="lg" color="primary" className={`py-0 ${this.state.noNavbar ? 'navbar-ontop' : ''}`} style={{ minHeight: '64px', borderTop: '2px solid rgba(255,255,255, 0.5)', borderBottom: '2px solid rgba(255,255,255, 0.5)' }}>
                            <Container fluid className={`${this.state.noNavbar ? 'd-none': ''}`}>
                                <NavbarBrand href="#" onClick={this.goHome} className="p-0">
                                    {
                                        this.state.mobile
                                            ? <img src={getAssetUrl('app/sidc-mobile-logo.png')} alt="SIDC" style={{ height: '50px', backgroundColor: 'rgba(255,255,255, 1)'}} />
                                            : <img src={getAssetUrl('app/sidcmini.png')} alt="SIDC" style={{ height: '50px', backgroundColor: 'rgba(255,255,255, 1)'}} />
                                    }
                                </NavbarBrand>
                                <NavbarToggler onClick={this.toggleNavbar} />
                                <Collapse isOpen={!this.state.collapsed} navbar className="justify-content-end">
                                    <Nav navbar>
                                        <NavItem className="mx-2">
                                            <Link className="nav-link" to="/">Home</Link>
                                        </NavItem>
                                        <UncontrolledDropdown className="mx-2" nav inNavbar>
                                            <DropdownToggle nav caret>
                                                SIDC
                                            </DropdownToggle>
                                            <DropdownMenu className="primary" right>
                                                <DropdownItem onClick={() => { navigate('/about') }}>
                                                    About SIDC
                                                </DropdownItem>
                                                <DropdownItem onClick={() => { navigate('/culture') }}>
                                                    SIDC Culture
                                                </DropdownItem>
												<DropdownItem onClick={() => { navigate('/data-privacy') }}>
                                                    Privacy Policy
                                                </DropdownItem>

                                                <DropdownItem divider />
                                                <DropdownItem onClick={() => { navigate('/chairperson-message') }}>
                                                    Chairperson's Message
                                                </DropdownItem>
                                                <DropdownItem onClick={() => { navigate('/board-of-directors') }}>
                                                    Board of Directors
                                                </DropdownItem>
                                                <DropdownItem onClick={() => { navigate('/organizational-chart') }}>
                                                    Organizational Chart
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <NavItem className="mx-2">
                                            <Link className="nav-link" to="/membership">Membership</Link>
                                        </NavItem>
                                        <NavItem className="mx-2">
                                            <Link className="nav-link" to="/career">Career</Link>
                                        </NavItem>
                                        <UncontrolledDropdown className="mx-2" nav inNavbar>
                                            <DropdownToggle nav caret>
                                                Offerings
                                            </DropdownToggle>
                                            <DropdownMenu className="primary" right>
                                                {/* <DropdownItem onClick={() => { navigate('/downloads') }}>
                                                    Downloads
                                                </DropdownItem> */}
                                                <DropdownItem tag="a" href={process.env.GATSBY_SITE_KOOPINOY}>
                                                    Koopinoy
                                                </DropdownItem>
                                                <DropdownItem tag="a" href={process.env.GATSBY_SITE_CONSUMER}>
                                                    Consumer Retailing
                                                </DropdownItem>
                                                <DropdownItem tag="a" href={process.env.GATSBY_SITE_AGRICULTURE}>
                                                    Agriculture
                                                </DropdownItem>
                                                 {/*<DropdownItem tag="a" href={process.env.GATSBY_SITE_ECO}>
                                                    Sorosoro Springs
                                                </DropdownItem>*/}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        {/* <NavItem className="mx-2">
                                            <Link className="nav-link" to="/anniversary">Anniversary</Link>
                                        </NavItem> */}
                                        <NavItem className="mx-2">
                                            <AnchorLink className="nav-link" href="#contact">Contact</AnchorLink>
                                        </NavItem>
                                    </Nav>
                                </Collapse>
                            </Container>
                        </BootstrapNavbar>
                    )
                }
            </Location>
        )
    }
}

export default Navbar
