import Header from './Header'
import Section from './Section'
import BranchesMap from './BranchesMap'
import Container from './Container'
import Benefit from './Benefit'
import Product from './Product'
import Parallax from './Parallax'
import Quote from './Quote'
import VideoProduct from './VideoProduct'
import ProductFeatures from './ProductFeatures'
import { TileModal, TileModalContainer } from './TileModal'

export {
    Header,
    Section,
    BranchesMap,
    Container,
    Benefit,
    Product,
    Parallax,
    Quote,
    VideoProduct,
    ProductFeatures,
    TileModal,
    TileModalContainer
}